var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom grid-stack-item-content",attrs:{"id":"clientsCard"}},[_c('div',{staticClass:"card-header",staticStyle:{"cursor":"move"}},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$t("client.clients")))])]),_c('div',{staticClass:"card-toolbar"},[_c('button',{staticClass:"btn btn-primary btn-hover",on:{"click":function($event){return _vm.$router.push({
            name: _vm.routeToCreate,
            params: {
              routeToTable: _vm.routeToTable,
              routeBreadcrumb: _vm.routeBreadcrumb,
              partnerId: _vm.partnerId
            }
          })}}},[_c('i',{staticClass:"fal fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("client.clientCreate"))+" ")])])]),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"card-body column"},[_c('TableWrapper',{attrs:{"meta":_vm.meta},on:{"reload-data":_vm.loadClients}},[_c('b-table',{staticClass:"dataTable table table-head-custom",attrs:{"responsive":"","items":_vm.clients,"fields":_vm.fields,"show-empty":true,"empty-text":_vm.$t('table.noRecords')},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b',{staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.showDetails(data.item.id)}}},[_c('i',{staticClass:"fal fa-pen"})]),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(_vm.$t('client.openMysql')),expression:"$t('client.openMysql')",modifiers:{"top":true,"noninteractive":true}}],staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.openMySql(data.item)}}},[_c('i',{staticClass:"fal fa-database"})]),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(_vm.$t('client.openMongo')),expression:"$t('client.openMongo')",modifiers:{"top":true,"noninteractive":true}}],staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.openMongo(data.item.tenant.tenantConnections)}}},[_c('i',{staticClass:"fal fa-database"})])]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"row flex-wrap py-3"},[(data.item && data.item.logo)?_c('div',{staticClass:"col-auto symbol symbol-50 py-0"},[_c('div',{staticClass:"symbol-label symbol-label-profile",style:(data.item.logo.link
                    ? 'background-image: url(' +
                      data.item.logo.link +
                      '); background-color: #ffffff;'
                    : '')})]):_vm._e(),_c('div',{staticClass:"col-auto flex-grow-1 py-0"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.name))]),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.number))])])])]}},{key:"cell(active)",fn:function(data){return [(data.item.active)?_c('span',{staticClass:"label label-lg label-light-success label-inline"},[_vm._v(" "+_vm._s(_vm.$t("general.active"))+" ")]):_c('span',{staticClass:"label label-lg label-light-danger label-inline"},[_vm._v(" "+_vm._s(_vm.$t("general.inactive"))+" ")])]}},{key:"cell(initialized)",fn:function(data){return [(data.item.initialized)?_c('div',[_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.$t("general.yes")))])]):_c('div',[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("general.no")))])])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }