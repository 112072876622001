<template>
  <div id="clientsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("client.clients") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary btn-hover"
          @click="
            $router.push({
              name: routeToCreate,
              params: {
                routeToTable: routeToTable,
                routeBreadcrumb: routeBreadcrumb,
                partnerId: partnerId
              }
            })
          "
        >
          <i class="fal fa-plus"></i>
          {{ $t("client.clientCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadClients">
        <b-table
          responsive
          :items="clients"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <button
              v-b-tooltip.top.noninteractive="$t('client.openMysql')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="openMySql(data.item)"
            >
              <i class="fal fa-database"></i>
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('client.openMongo')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="openMongo(data.item.tenant.tenantConnections)"
            >
              <i class="fal fa-database"></i>
            </button>
          </template>
          <template #cell(name)="data">
            <div class="row flex-wrap py-3">
              <div
                v-if="data.item && data.item.logo"
                class="col-auto symbol symbol-50 py-0"
              >
                <div
                  class="symbol-label symbol-label-profile"
                  :style="
                    data.item.logo.link
                      ? 'background-image: url(' +
                        data.item.logo.link +
                        '); background-color: #ffffff;'
                      : ''
                  "
                ></div>
              </div>
              <div class="col-auto flex-grow-1 py-0">
                <span class="d-block">{{ data.item.name }}</span>
                <span class="text-muted">{{ data.item.number }}</span>
              </div>
            </div>
          </template>
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span v-else class="label label-lg label-light-danger label-inline">
              {{ $t("general.inactive") }}
            </span>
          </template>
          <template #cell(initialized)="data">
            <div v-if="data.item.initialized">
              <span class="text-success">{{ $t("general.yes") }}</span>
            </div>
            <div v-else>
              <span class="text-danger">{{ $t("general.no") }}</span>
            </div>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { formatDate } from "@/components/Tools/modifiers";
import Clients from "@/components/Admins/Clients/clients";
import { mapGetters } from "vuex";
import TableWrapper from "@/components/Tools/TableWrapper";

export default {
  components: { TableWrapper },
  props: {
    partnerArea: {
      type: Boolean,
      default: false
    },
    routeToCreate: {
      type: String,
      default: "adminTenantsCreate"
    },
    routeToTable: {
      type: String,
      default: "adminTenants"
    },
    routeToDetails: {
      type: String,
      default: "adminTenantsDetails"
    },
    routeBreadcrumb: {
      type: String,
      default: "/admin/clients"
    }
  },
  data() {
    return {
      clients: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "name",
          label: this.$t("table.tenant"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true
        },
        {
          key: "initialized",
          label: this.$t("table.initialized"),
          sortable: true
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      // Meta info
      meta: {},
      isBusy: false,
      adminerHeaders: {
        headers: {
          Accept:
            "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
          "Content-Type": "multipart/form-data"
        }
      }
    };
  },
  computed: {
    ...mapGetters(["selectedPartner"]),
    ...mapGetters("route", ["requestParams", "cancelToken"]),
    partnerId: function () {
      return this.partnerArea && this.selectedPartner
        ? this.selectedPartner.id
        : null;
    }
  },
  watch: {
    selectedPartner: function () {
      if (this.partnerArea) {
        this.loadClients();
      }
    }
  },
  mounted() {
    this.loadClients();
    this.$root.$on("clientsTableRefresh", () => {
      this.refreshCard();
    });
  },
  methods: {
    loadClients() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Clients.getAll(params, this.partnerId, cancelToken)
        .then(response => {
          this.clients = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    showDetails(id) {
      this.$router.push({ name: this.routeToDetails, params: { id: id } });
    },
    async openMySql(client) {
      let res = await Clients.checkDBAuth(client);

      if (res.success) {
        window.open(res.url, "_blank");

        return;
      }

      await Swal.fire(
        this.$t("client.error"),
        this.$t("client.couldNotOpenDatabase"),
        "error"
      );
    },
    openMongo(connections) {
      let connection = connections.find(
        connection => connection.type === "mongodb"
      );
      if (!connection) return;
      let url = process.env.VUE_APP_MONGO_DB_INTERFACE;
      let password = encodeURIComponent(connection.password);
      let username = encodeURIComponent(connection.username);
      url += `/login?user=${username}&password=${password}&host=${connection.host}&port=${connection.port}&database=${connection.database}`;
      window.open(url, "_blank");
    },
    deleteClient(id, name) {
      Swal.fire({
        title: this.$t("dialogs.confirmDeletion"),
        text: this.$t("dialogs.deleteDialogDescription", { user: name }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          Clients.delete(id)
            .then(() => {
              this.$toast.fire({
                title: this.$t("clients.clientDeleted"),
                icon: "success"
              });
              if (this.clients.length === 1 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.refreshCard();
              }
            })
            .catch(error => {
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
      });
    }
  }
};
</script>
